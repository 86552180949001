const servicesData = [
	{
		category: 'General Advisory Services',
		title: 'General Advisory Services',
		description: [
			'SWOT analysis',
			'Evaluation of project needs',
			'Overall project evaluation',
			'2-3 discussions inclusive',
		],
		price: '40$',
	},
	{
		category: 'Marketing',
		title: 'Marketing',
		description: [
			'Evaluation of marketing needs: $30',
			'General marketing strategy: $40',
			'Specific product marketing strategy: $70',
			"Note: Implementation is client-driven. Each draft aligns with the project's budget and capability.",
		],
	},
	{
		category: 'Tokenomics',
		title: 'Tokenomics',
		description: [
			'Evaluation of distribution and mechanics: free with other service (standalone: $20)',
			'Draft distribution: $20 with other service (standalone: $50)',
			'Draft mechanics: $200 with other service (standalone: $500)',
		],
	},
	{
		category: 'PR/Communication',
		title: 'PR/Communication',
		description: [
			'Community management: $120/month/admin',
			'Collaborative PR with other projects: $70/month',
			'Growth strategy via partnerships/collaborations: $150 (2 month service)',
			'Professional Infographic, whitepaper, litepaper PDF Documents: $25 up to 5 pages. Note: Every extra page +2.5$',
			'Content creator for Medium and X (Twitter) starting from: $60 for medium ($100 for 2 articles), $35 for Twitter/thread (minimum to order 3 threads/month), Combo offer $100 (1 medium article + 2 threads). Note: for long term engagements special offers are on the table.',
		],
	},
	{
		category: 'Promo Services',
		title: 'Promo Services',
		description: [
			'Short promo video (max 15 sec): $12',
			'Long promo video (max 1 min): $24',
			'Extended presentation (above 1 min): $36',
			'Banners: $4/piece (min. 5 pieces, basic design; price varies by complexity)',
			'Infogram: $10 (min. 2 pieces)',
			'Visual identity package: $120',
			'Bulk Offer - $70: 2 short videos, 10 banners, 1 extended presentation',
		],
	},
	{
		category: 'Merch Design',
		title: 'Merch Design',
		description: [
			'10$/design/t-shirt single design',
			'15$/design/hoodie single design',
			'15$/2 sides t-shirt',
			'25$/2 sides hoodie',
			'Other merch designs can be acquired depending on project needs',
			'Note: This service refers to design work only, without printing or delivering merch.',
		],
	},
	{
		category: 'Developer works',
		title: 'Developer works',
		description: [
			'Landing pages',
			'Dapps',
			'Smart contract dev',
			'Front end and back end',
			'Professional website design starting from 1000 euro',
			'Visual identity starting from 700 euro',
			'Multiversx dapps',
			'Multiversx smart contracts',
			'Automation scripts and devops',
			"Note: This is a third party service. Pricing depends on client's needs and work time.",
		],
	},
	{
		category: 'Audio Production',
		title: 'Audio Production',
		description: [
			'Sound effects & music production',
			'Syncing the sound with the video',
			'Bulk price starts with $50 and can go up depending on the complexity. Open to discussions for more particular/custom jobs.',
		],
	},
	{
		category: 'Video Production',
		title: 'Video Production',
		description: [
			'Video presenting the project/product from $300 (max 5 min) up to $1000 (8-10 min) depending on the complexity of the work in hand',
		],
	},
	{
		category: 'Support Services',
		title: 'Support Services',
		description: [
			'Creating AI NFT/SFT collections: Can help with art, json, mint - starting from 10 EGLD, Collection Verify - Starting from 5 EGLD',
			'3D rendering of logos and 3D objects design: Services in 3D art, along with integration into engines such as Unity, as well as rendering and blender services, starting from $150. Price may vary due to the complexity of the work needed. Note: These are collaborative services with third-party specialists. Agreements and evaluations will be made directly with them through BlazingWeb3.',
		],
	},
];

export default servicesData;
